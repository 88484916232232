@import '~antd/dist/antd.css';
.box{width: 100px;height: 100px;background-color: #0000FF}
#viewDiv{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}
.site-layout .site-layout-background {
    background: #fff;
}
#components-layout-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.3);
    margin: 16px;
}
.trigger{
    margin-left: 20px;
}
p{margin: 0; padding: 0}
#mainPage{
    height: 100%;
}