html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}
.App{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

#root{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.test{
    margin: 0;
    padding: 0;
    height: 100px;
    width: 100%;
    background-color: aqua;
}
.ant-layout{
    height: 100%;
}
.ant-row{
    height: 100%;
}
.ant-col{
    padding: 10px;
}